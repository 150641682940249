import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>Google Analytics</p>
        <ul style={{ width: '770px' }}>
          <li>Event from old tagmanager</li>
          <li>Create server container</li>
          <li>Mapping domain (root as webpage)</li>
          <li>GA universal add : transport url to server container</li>
          <li>G4 add : end to server container</li>
        </ul>
        <p>Facebook pixel</p>
        <ul style={{ width: '770px' }}>
          <li>Event from old pixel in tagmanager</li>
          <li>Receive event from gtag (by google universal tracking)</li>
          <li>Implement new event from server container</li>
        </ul>
        {/*
        <button onClick={requestGeolocation}>
          get Geolocation
        </button>
         */}
      </header>
    </div>
  );
}

export default App;


// function requestGeolocation() {
//   console.log('requestGeolocation')
//   var axios = require("axios").default;

//   var options = {
//     method: 'GET',
//     url: 'https://assets.jenosize.io/api/v1/geolocation/',
//     params: {
//       filter_all: '{"provinceId":"BKK","districtId":1001,"subDistrictId":100101}',
//       filter: '{"provinceId":"BKK"}',
//       postcode: '10210',
//       search: 'ดอนเมือง',
//       threshold: '0.58',
//       pick: 'provinceId,provinceDesc,provinceDescEn,districtId,districtDesc,districtDescEn',
//       unique: 'provinceId,districtId',
//       offset: '0',
//       limit: '10'
//     },
//     headers: { Authorization: 'Bearer vDyWnI9PY4gjWH2dCSpT' }
//   };

//   axios.request(options).then(function (response) {
//     console.log(response.data);
//   }).catch(function (error) {
//     console.error(error);
//   });
// }
